






































import {
  defineComponent,
  onMounted,
  useContext,
  useAsync,
  useMeta,
  useRouter,
  useStore,
  useRoute,
  watch,
  computed,
  ref,
  provide,
  onBeforeMount,
  unref
} from '@nuxtjs/composition-api'
import { StoryData } from 'storyblok-js-client/types'

import useSWRV from 'swrv'
import helper from '@/utils/helper'
import useStoryblok from '@/utils/storyblok'
import { State } from '~/types/store'
import User from '~/models/User'
import Appointment from '~/models/Appointment'
import AppointmentRoom from '~/models/AppointmentRoom'
import AppointmentRoomOld from '~/models/AppointmentRoomOld'
import SubscriptionPlan from '~/models/SubscriptionPlan'
import AppointmentType from '~/models/AppointmentType'
import Calendar from '~/models/Calendar'
import Tag from '~/models/Tag'
import { useModal } from '~/utils/useModel'
import { StoryblokPluginSeo } from '~/types/storyblok-vue'
import Game from '~/models/Game'
import Order from '~/models/Order'
import GameAccount from '~/models/GameAccount'
// import GameRank from '~/models/GameRank'

export default defineComponent({
  auth: false,
  setup() {
    const { dashify, checkScopes } = helper()
    const { getStory, listenToStoryBridgeChanges, getStories, getTemplate } = useStoryblok()
    const nuxtContext = useContext()
    const { title, titleTemplate, meta } = useMeta()
    const router = useRouter()
    const route = useRoute()
    const store = useStore<State>()
    const userRepo = store.$repo(User)
    const appointmentRepo = store.$repo(Appointment)
    const appointmentRoomRepo = store.$repo(AppointmentRoom)
    const oldAppointmentRoomRepo = store.$repo(AppointmentRoomOld)
    const appointmentTypeRepo = store.$repo(AppointmentType)
    const subscriptionPlansRepo = store.$repo(SubscriptionPlan)
    const calendarRepo = store.$repo(Calendar)
    const tagRepo = store.$repo(Tag)
    const orderRepo = store.$repo(Order)
    const gamesRepo = store.$repo(Game)
    const gameAccountsRepo = store.$repo(GameAccount)
    // const gameRanksRepo = store.$repo(GameRank)
    const isLoggedIn = computed(() => store.state.auth.loggedIn)
    const [isModalOpen, modalConfig] = useModal()
    const pageAuth = ref('')
    const bannerTop = ref([])
    const bannerBottom = ref([])
    const userData = computed(() =>
      userRepo
        .with('subscriptions', (query) => {
          query.with('plan')
          query.with('features', (query) => {
            query.with('usage')
          })
        })
        .with('store_credits', (query) => {
          query.with('product')
        })
        .with('orders')
        .with('user_coach_profile')
        .with('game_account')
        .with('latest_game')
        .find(store.state.auth.user)
    )
    const isStoryblokEdit = ref(false)
    const bannerPadding = ref(true)

    // const gamerslocalStorage = localStorage.getItem('gamersAcademy')

    // console.log(JSON.parse(gamerslocalStorage).bannerIds.length)

    nuxtContext.$bus.on('closeBanner', () => {
      if (!isStoryblokEdit.value) {
        bannerPadding.value = false
      }
    })

    if (!store.state.language && nuxtContext.params.value.lang) {
      store.dispatch('setLanguage', nuxtContext.params.value.lang)
    }
    const storyOptions = {
      path: route.value.path,
      context: nuxtContext
    }
    console.log('storyOptions', storyOptions);
    let story: any = ref({
      story: {},
      settingsStory: {}
    })
    titleTemplate.value = '%s | Gamers Academy'

    provide('userData', userData)

    if (nuxtContext.payload && nuxtContext.payload.store) {
      subscriptionPlansRepo.save(nuxtContext.payload.store.plans)
    }

    const setMeta = (data: StoryData) => {
      if (data) {
        let seoTitle = data.name
        let seoMeta = {
          og_title: seoTitle,
          og_site_name: 'GAMERS ACADEMY',
          description: 'Gamers Academy - GET READY FOR FIFA 22 AND BOOST YOUR GAME TO THE NEXT LEVEL',
          og_url: nuxtContext.$config.api + route.value.fullPath,
          twitter_url: nuxtContext.$config.api + route.value.fullPath,
          twitter_card: 'summary',
          twitter_title: seoTitle
        }
        if (data.content && data.content.seo) {
          const seo: StoryblokPluginSeo = data.content.seo
          seoTitle = seo.title ? seo.title : seoTitle
          seoMeta = {
            ...seoMeta,
            ...seo,
            og_title: seo.title ? seo.title : seoTitle,
            twitter_title: seo.twitter_title ? seo.twitter_title : seoTitle,
            og_description: seo.og_description ? seo.og_description : seo.description,
            twitter_description: seo.twitter_description ? seo.twitter_description : seo.description
          }
          delete seoMeta._uid
          if (data.content.component === 'blog-page') {
            seoMeta = {
              ...seoMeta,
              og_image: data.content.image?.filename,
              twitter_image: data.content.image?.filename,
              image: data.content.image?.filename,
              description: data.content.short_text,
              og_description: seo.og_description ? seo.og_description : data.content.short_text,
              twitter_description: seo.twitter_description ? seo.twitter_description : data.content.short_text
            }
          }
        }
        meta.value = Object.entries(seoMeta).map((entry) => {
          return {
            hid: entry[0].replace('_', ':'),
            name: entry[0].replace('_', ':'),
            content: entry[1]
          }
        })
        title.value = seoTitle
      }
    }

    const fillBill = async () => {
      console.log(unref(userData)?.email, 'cartUpdate')
      try {
        await window.Snipcart.api.cart.update({
          email: unref(userData)?.email,
          // metadata: {
          //   customMetadataKey: 'value'
          // },
          billingAddress: {
            name: unref(userData)?.name,
            address1: 'Online',
            city: 'Gamers Academy',
            country: 'DE',
            postalCode: 'GmbH'
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    const getOptions: (path: string) => GetStoryblokOptions = (path) => {
      return {
        path,
        isUuid: true,
        context: nuxtContext
      }
    }

    const settingsPage = async (page) => {
      const settings = await getStories(nuxtContext, {
        by_slugs: `${nuxtContext.i18n.locale}/settings/*`
      })
      const settingStory = ref({})

      settingStory.value = settings.find((setting) => setting.slug === page.content.component)

      if (page.content.component === 'settings') {
        settingStory.value = settings.find((setting) => setting.slug === page.slug)
      }

      if (!settingStory.value) {
        settingStory.value = settings.find((setting) => setting.slug === 'global')
      }

      const content = settingStory.value.content

      return {
        ...content,
        header_content: content.header_content
          ? content.header_content
          : content.header_template
          ? (await getStory(getOptions(content.header_template))).content.content
          : {},
        footer_content: content.footer_content
          ? content.footer_content
          : content.footer_template
          ? (await getStory(getOptions(content.footer_template))).content.content
          : {},
        sidebar_left_content: content.sidebar_left_content
          ? content.sidebar_left_content
          : content.sidebar_left_template_id
          ? (await getStory(getOptions(content.sidebar_left_template_id))).content.content
          : {}
      }
    }

    if (!/%EF%BF%BD/.test(storyOptions.path)) {
      story = useAsync(
        async () => {
          if (nuxtContext.payload) {
            setMeta(nuxtContext.payload.story)
            return nuxtContext.payload
          }
          return await getStory(storyOptions).then(async (data: StoryData) => {
            if (!data) {
              router.push(nuxtContext.localePath('/not-found'))
            } else {
              setMeta(data)
              return {
                story: data,
                settingsStory: await settingsPage(data)
              }
            }
          })
        },
        storyOptions.path !== '/' ? storyOptions.path.replace(/\/$/, '') : 'home'
      )
    }

    provide('story', story)

    onBeforeMount(() => {
      if (subscriptionPlansRepo.all().length === 0) {
        mutatePlans()
      }
      if (tagRepo.all().length === 0) {
        mutateTags()
      }

      mutateUser()

      if ((nuxtContext.isDev || nuxtContext.$config.dev) && window.location !== window.parent.location) {
        isStoryblokEdit.value = true
      }
    })

    const triggerAnalytics = async (rootStory) => {
      if (route.value && route.value.path) {
        if (route.value.path.includes('de/fortnite/pricing-credits') && window.gtag) {
          window.gtag('event', 'conversion', {
            send_to: 'AW-709617753/8m1NCImG6KkDENnQr9IC'
          })
        }
      }
      if (rootStory && rootStory.story) {
        rootStory = rootStory.story
        setMeta(rootStory)

        if (rootStory.content) {
          nuxtContext.$colorMode.preference = rootStory.content.color_theme ? rootStory.content.color_theme : 'dark'
        }

        if (isLoggedIn && userData.value && !isStoryblokEdit.value && process.client) {
          await mutateUser()
          if (typeof Matomo !== 'undefined') {
            Matomo.getTracker().setUserId(userData.value.id + '-' + userData.value.name)
          } else {
            window._paq.push(['setUserId', userData.value.id + '-' + userData.value.name])
          }

          if (
            rootStory &&
            rootStory.content &&
            rootStory.content.track_order &&
            route.value.query &&
            route.value.query.orderId
          ) {
            const order = orderRepo.with('plans').with('products').find(parseInt(route.value.query?.orderId))
            const tracker = Matomo.getTracker()
            let discount = null
            if (order && order.plans.length > 0) {
              tracker.addEcommerceItem(order.plans[0].sku, order.plans[0].name, 'Subscription', order.plans[0].price)
              discount = order.used_voucher_code ? order.plans[0].price - order.amount : null
            }
            if (order && order.products.length > 0) {
              tracker.addEcommerceItem(
                order.products[0].sku,
                order.products[0].title,
                'Appointment',
                order.products[0].price
              )
              discount = order.used_voucher_code ? order.products[0].price - order.amount : null
            }
            if (order && order.state === 'success') {
              if (order && order.plans.length > 0) {
                nuxtContext.$fb.query('track', 'Lead')
                if (window.gtag) {
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-709617753/nY8gCNWi-P8CENnQr9IC',
                    value: order.plans[0].price,
                    currency: order.plans[0].currency,
                    transaction_id: order.plans[0].id
                  })
                }
                if (window.ttq) {
                  window.ttq.track('CompleteRegistration')
                }

                if (!order.plans[0].trial_period > 0) {
                  tracker.trackEvent('subscription', 'signupFreeTrial', user.value.subscriptions[0].id)
                } else {
                  tracker.trackEvent('subscription', 'signup', user.value.subscriptions[0].id, order.amount)
                  tracker.trackEcommerceOrder(route.value.query.orderId, order.amount, null, null, null, discount)
                  nuxtContext.$fb.query('track', 'Purchase', {
                    currency: order.plans[0].currency,
                    value: order.amount
                  })
                  if (window.ttq) {
                    window.ttq.track('CompletePayment', {
                      content_name: order.plans[0].name,
                      value: order.amount,
                      currency: order.plans[0].currency
                    })
                  }
                }
              }

              if (order && order.products.length > 0) {
                if (!user.value.subscriptions && parseInt(order.amount) !== 0) {
                  tracker.trackEcommerceOrder(route.value.query.orderId, order.amount, null, null, null, discount)
                }

                // nuxtContext.$fb.query('track', 'Purchase', {
                //   currency: 'EUR', value: order.amount
                // })
                // if (window.ttq) {
                //   window.ttq.track('CompletePayment', {
                //     content_name: order.plans[0].name,
                //     value: order.amount,
                //     currency: order.plans[0].currency,
                //   })
                // }
              }
            }
          }
        }
      }
    }

    const fetcher = async (): User | {} => {
      if (!nuxtContext.$auth.loggedIn || process.server) {
        return {}
      }
      await nuxtContext.$axios('/sanctum/csrf-cookie')
      return await nuxtContext.$axios
        .get('/api/user')
        .then((response) => response.data.data)
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data.message === 'Unauthenticated.' &&
            error.response.status === 401
          ) {
            if (nuxtContext.$auth.loggedIn) {
              nuxtContext.$auth.logout()
            }
          }
          return {}
        })
    }

    const customFetcher = async (url: string) => {
      if (nuxtContext.payload && nuxtContext.payload.store) {
        return []
      }
      await nuxtContext.$axios('/sanctum/csrf-cookie')
      return await nuxtContext.$axios.get(url).then((response) => response.data.data.values)
    }

    const customFetcherLoggedIn = async (url: string) => {
      if (nuxtContext.payload && nuxtContext.payload.store) {
        return []
      }
      if (!nuxtContext.$auth.loggedIn) {
        return []
      }
      await nuxtContext.$axios('/sanctum/csrf-cookie')
      return await nuxtContext.$axios.get(url).then((response) => response.data.data.values)
    }

    const customFetcherItems = async (url: string) => {
      if (nuxtContext.payload && nuxtContext.payload.store) {
        return []
      }
      await nuxtContext.$axios('/sanctum/csrf-cookie')
      return await nuxtContext.$axios.get(url).then((response) => response.data.data.items)
    }

    const parametrizedCustomFetcher = function (_p, fetcherFn) {
      const params = typeof _p === 'object' ? new URLSearchParams(_p).toString() : _p

      return function (url: string) {
        if (params) {
          if (!url.includes('?')) {
            url += '?' + params
          } else {
            url += '&' + params
          }
        }
        return fetcherFn(url)
      }
    }

    const checkAuth = (data: any, withToast: boolean = false) => {
      const auth = data.content.auth || []
      if (
        !checkScopes(nuxtContext, [...(Array.isArray(auth) ? auth : [auth]), ...(data.content.plan_features || [])]) &&
        !isStoryblokEdit.value
      ) {
        const authProp = typeof auth === 'object' ? auth[0] : auth

        if (authProp === 'registered') {
          router.push(nuxtContext.localePath('/login'))
        }
        if (authProp === 'notRegistered') {
          router.push(nuxtContext.localePath('/member/dashboard'))
        }

        if (authProp === 'verified') {
          router.push(nuxtContext.localePath('/email-verification?verify=1'))
        }
        if (data.content.plan_features && data.content.plan_features.length) {
          if (!isLoggedIn.value) {
            if (withToast) {
              setTimeout(
                () =>
                  nuxtContext.$toast &&
                  nuxtContext.$toast.warning("You don't have access to this feature. Please log in"),
                500
              )
            }
            router.push(nuxtContext.localePath('/pricing'))
          } else {
            if (withToast) {
              setTimeout(
                () =>
                  nuxtContext.$toast &&
                  nuxtContext.$toast.warning("You don't have access to this feature. Upgrade now to get access"),
                500
              )
            }
            router.push(nuxtContext.localePath('/pricing'))
          }
        }
        return process.server
      }
      return true
    }
    const swrvOptions = {
      revalidateOnFocus: false
    }

    const { data: user, mutate: mutateUser } = useSWRV(isLoggedIn.value && 'user', fetcher, swrvOptions)
    const { data: appointments, mutate: mutateAppointments } = useSWRV(
      isLoggedIn.value && '/api/booking/appointments',
      customFetcherLoggedIn,
      swrvOptions
    )
    const { data: subscriptionPlans, mutate: mutatePlans } = useSWRV(
      '/api/subscription/plans',
      customFetcherItems,
      swrvOptions
    )
    const { data: coaches, mutate: mutateCoaches } = useSWRV(
      '/api/user/coaches?language=' + nuxtContext.i18n.locale,
      customFetcherItems,
      swrvOptions
    )
    const { data: gameAccounts, mutate: mutateGameAccounts } = useSWRV(
      isLoggedIn.value && '/api/user/gameAccounts',
      customFetcherItems,
      { ...swrvOptions, dedupingInterval: 0 }
    )

    /*     const {data: gameRanks, mutate: mutateGameRanks } = useSWRV(

    ) */
    const { data: games, mutate: mutateGames } = useSWRV('/api/games', customFetcherItems, swrvOptions)
    const { data: appointmentRooms, mutate: mutateRooms } = useSWRV(
      '/api/booking/availability/classes',
      customFetcher,
      swrvOptions
    )
    const { data: oldAppointmentRooms, mutate: mutateOldRooms } = useSWRV(
      '/api/booking/availability/classes?nowSubstractionMinutes=144000',
      customFetcher,
      swrvOptions
    )
    const { data: appointmentTypes, mutate: mutateAppointmentTypes } = useSWRV(
      '/api/booking/appointment-types',
      customFetcher,
      swrvOptions
    )
    const { data: calendars } = useSWRV('/api/booking/calendars', customFetcher, swrvOptions)
    const { data: tags, mutate: mutateTags } = useSWRV(
      '/api/tags?language=' + nuxtContext.i18n.locale,
      customFetcher,
      swrvOptions
    )

    nuxtContext.$bus.on('refetchStore', (data) => {
      const type = typeof data === 'string' ? data : data.type
      const params = typeof data === 'object' ? data.params : undefined

      switch (type) {
        case 'user':
          mutateUser()
          break
        case 'coaches':
          mutateCoaches()
          break
        case 'gameAccounts':
          mutateGameAccounts(parametrizedCustomFetcher(params, customFetcherItems))
          break
        case 'games':
          mutateGames()
          break
        case 'plans':
          mutatePlans()
          break
        case 'appointments':
          mutateAppointments()
          break
        case 'appointmentTypes':
          mutateAppointmentTypes()
          break
        case 'tags':
          mutateTags()
          break
        case 'appointmentRooms':
          mutateRooms()
          break
        case 'appointmentRoomsOld':
          mutateOldRooms()
          break
        default:
          break
      }
    })

    nuxtContext.$auth.$storage.watchState('loggedIn', () => {
      story.value.story && checkAuth(story.value.story)
    })

    const banners = useAsync(() => {
      if (nuxtContext.payload) {
        const filter = new RegExp(`${nuxtContext.i18n.locale}/banners`)
        const bannersByLaunguage = nuxtContext.payload.bannerStories.filter((story: StoryData) =>
          filter.test(story.full_slug)
        )

        return {
          top: bannersByLaunguage.filter((banner) => banner.content.position === 'top'),
          bottom: bannersByLaunguage.filter((banner) => banner.content.position === 'bottom')
        }
      }

      return getStories(nuxtContext, {
        by_slugs: `${nuxtContext.i18n.locale}/banners/*`,
        resolve_relations: 'banner.display_for_parents_of'
      }).then((banners) => {
        return {
          top: banners.filter((banner) => banner.content.position === 'top'),
          bottom: banners.filter((banner) => banner.content.position === 'bottom')
        }
      })
    })

    const axios = nuxtContext.$axios.create({
      withCredentials: true,
      baseURL: nuxtContext.$config.middlewareApi
    })

    const login = async (loginData) => {
      return await nuxtContext.$auth
        .loginWith('laravelSanctum', {
          data: loginData
        })
        .then(async () => {
          const userData = await axios.get('/api/user').then((response) => response.data.data)
          if (userData) {
            nuxtContext.$auth.setUser(userData.id)
            window._paq.push(['setUserId', userData.id + '-' + userData.name])
            userRepo.save(userData)

            setTimeout(function () {
              window.Snipcart.api.theme.cart.close()
            }, 3000)
          }
          return userData
        })
        .catch((error) => {
          if (error.response) {
            nuxtContext.$toast.error(error.response.data.message)
          } else {
            console.error(error)
          }
        })
    }

    onMounted(() => {
      document.addEventListener('snipcart.ready', () => {
        window.Snipcart.events.on('item.adding', (cartItem) => {
          console.log(cartItem)
          nuxtContext.$snipcart.setLanguage(nuxtContext.i18n.locale)
        })
        window.Snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              send_to: 'AW-709617753/4XJLCI7j7Y4DENnQr9IC',
              value: cartConfirmResponse.total,
              currency: cartConfirmResponse.currency,
              transaction_id: cartConfirmResponse.token
            })
          }

          const customerEmail = cartConfirmResponse.email
          if (cartConfirmResponse.customFields !== undefined) {
            const passwordField = cartConfirmResponse.customFields.find((field) => {
              return field.name === 'password'
            })
            if (passwordField !== undefined) {
              const customerPassword = passwordField.value
              const loginData = {
                email: customerEmail,
                password: customerPassword
              }
              login(loginData)
            }
          } else {
            setTimeout(function () {
              window.Snipcart.api.theme.cart.close()
            }, 4000)
          }
        })
        window.Snipcart.events.on('cart.created', (cartState) => {
          console.log(cartState)
          fillBill()
        })

        window.Snipcart.events.on('theme.routechanged', (routesChange) => {
          if (routesChange.from.includes('/order/') && routesChange.to === '/' && nuxtContext.$auth.loggedIn) {
            router.push(nuxtContext.localePath('/member/dashboard'))
          }
        })
      })

      const _mtm = (window._mtm = window._mtm || [])
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
      triggerAnalytics(story.value)
      if ((nuxtContext.isDev || nuxtContext.$config.dev) && window.location !== window.parent.location) {
        listenToStoryBridgeChanges(story)
      }

      if (route.value.query.action) {
        const data = {
          ...(route.value.query.actionParamWithStoreData && { dataModel: store.state.formData }),
          ...(route.value.query.actionParamStoreTemplateId && {
            content: getTemplate(store.state.formTemplateId, {
              templateProperty: 'content',
              context: nuxtContext
            })
          }),
          ...(route.value.query.actionParamContentId && {
            content: getTemplate(route.value.query.actionParamContentId, {
              templateProperty: 'content',
              context: nuxtContext
            })
          })
        }
        nuxtContext.$bus.emit(route.value.query.action, data)
      }
    })

    watch(user, (apiData) => {
      userRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'user')
      if (userData.value) {
        console.info('Logged in')
        fillBill()
        window._paq.push(['setUserId', userData.value.id + '-' + userData.value.name])
      }
    })
    watch(coaches, (apiData) => {
      userRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'coaches')
    })
    watch(gameAccounts, (apiData) => {
      gameAccountsRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'gameAccounts')
    })
    // watch(gameRanks, (apiData) => {
    //   gameRanksRepo.save(apiData)
    // })
    watch(calendars, (apiData) => {
      calendarRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'calendars')
    })
    watch(tags, (apiData) => {
      tagRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'tags')
    })
    watch(games, (apiData) => {
      gamesRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'games')
    })
    watch(appointments, (apiData) => {
      appointmentRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'appointments')
    })
    watch(oldAppointmentRooms, (apiData) => {
      oldAppointmentRoomRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'appointmentRoomsOld')
    })
    watch(appointmentRooms, (apiData) => {
      appointmentRoomRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'appointmentRooms')
    })
    watch(subscriptionPlans, (apiData) => {
      subscriptionPlansRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'plans')
    })
    watch(appointmentTypes, (apiData) => {
      appointmentTypeRepo.save(apiData)
      nuxtContext.$bus.emit('refetchStoreFinished', 'appointmentTypes')
    })

    watch(story, (storyValue) => {
      triggerAnalytics(storyValue)
    })

    return {
      story,
      dashify,
      pageAuth,
      checkAuth,
      login,
      isModalOpen,
      modalConfig,
      setMeta,
      bannerTop,
      bannerBottom,
      banners,
      isStoryblokEdit,
      settingsPage,
      bannerPadding
    }
  },
  nuxtI18n: {},
  head: {}
})
